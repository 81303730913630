import { IconFile, IconSlope, IconStatistics, IconUser } from 'assets/images'
import { useRole } from 'hooks'
import { t } from 'i18n'
import { If } from 'react-extras'
import { connect } from 'react-redux'
import { Dispatch as ReduxDispatch, bindActionCreators } from 'redux'
import { Role, UserInfo } from 'store/ducks'
import * as AuthActions from 'store/ducks/auth/actions'
import { ApplicationState } from '../../store'
import ChangeResort from './change-resort'
import SetDefaultSeason from './set-default-season'
import { SidebarContainer, SidebarItem } from './styled'

interface Props {
  user: UserInfo
  expanded: boolean
  changeResortRequest: (resortId: string) => void
}

const Sidebar = ({ expanded, user, changeResortRequest }: Props) => {
  const { resort: { name } = {} } = user

  const isAdmin = useRole([Role.RESORT_ADMINISTRATOR, Role.SYS_ADMIN])
  return (
    <SidebarContainer expanded={expanded} className="no-print">
      <ChangeResort
        name={name}
        expanded={expanded}
        changeResortRequest={changeResortRequest}
      />
      <div style={{ marginBottom: '2rem' }}>
        <SidebarItem to="/reports">
          <IconFile fill="#fff" width={20} /> {expanded && t`reportPage`}
        </SidebarItem>
        <If condition={isAdmin}>
          <SidebarItem to="/statistics">
            <IconStatistics width={20} /> {expanded && t`statistics`}
          </SidebarItem>
          <SidebarItem to="/users">
            <IconUser width={20} />
            {expanded && t`users`}
          </SidebarItem>
          <SidebarItem to="/slopes">
            <IconSlope width={20} />
            {expanded && t`slopes`}
          </SidebarItem>
        </If>
        {/* <SidebarItem to="/resort-information">
          <IconStatistics width={20} /> {expanded && t`resortInformation`}
        </SidebarItem> */}
      </div>
      <SetDefaultSeason expanded={expanded} />
    </SidebarContainer>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.auth.data
})

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
  bindActionCreators(AuthActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
