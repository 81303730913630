import { AxiosResponse } from 'axios'
import moment from 'moment'
import { ReportsExcelResponse, Seasons } from 'store/ducks'
import api from './baseApi'

export interface StatisticProps {
  commonPlace: string
  mostUsedSafetyEquipment: string
  totalInjuries: number
  commonInjuries: StatisticObjectProps[]
  injuriesByDate: StatisticObjectProps[]
  injuriesByPlace: StatisticObjectProps[]
  injuriesBySlope: InjuriesBySlopeProps[]
  injuriesBySafetyEquipments: StatisticObjectProps[]
  injuriesByGender: StatisticObjectProps[]
  injuriesByNationality: StatisticObjectProps[]
  transportInsideResort: StatisticObjectProps[]
  transportOutsideResort: StatisticObjectProps[]
  treatedBy: StatisticObjectProps[]
  injuriesBySkillLevel: StatisticObjectProps[]
  injuriesByEquipment: StatisticObjectProps[]
  injuriesDuringIntroduction: StatisticObjectProps[]
  injuriesByRented: StatisticObjectProps[]
  injuriesByRangeAge: StatisticObjectProps[]
  filteredReportsIdsArray: number[] | any
}

export interface StatisticObjectProps {
  name: string
  total: number
}

export interface InjuriesBySlopeProps {
  commonInjury: string
  id: string
  mostUsedEquipment: string
  mostUsedSafetyEquipment: string
  name: string
  resortId: string
  total: number
}

export interface StatisticFilterProps {
  resortId: string
  startDate: string
  endDate: string
  gender?: Gender | ''
  filterSeason?: Seasons
  nationality?: string
  minAge: number
  maxAge: number
}

export enum FilterDate {
  THIS_YEAR = 'THIS_YEAR',
  TODAY = 'TODAY',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  YEAR = 'YEAR'
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  ALL = 'ALL'
}

export const getStatistics = async (filters: StatisticFilterProps) => {
  const { startDate, endDate, ...rest } = filters
  const params = {
    ...rest,
    initialDate: moment(startDate).format('YYYY-MM-DD 00:00:00'),
    finalDate: moment(endDate).format('YYYY-MM-DD 23:59:59')
  }

  const response: AxiosResponse<StatisticProps> = await api.get('statistics', {
    params
  })
  return response.data
}

export const getReportsExcel = async (
  filters: StatisticFilterProps
): Promise<ReportsExcelResponse> => {
  const { startDate, endDate, ...rest } = filters
  const params = {
    ...rest,
    initialDate: moment(startDate).format('YYYY-MM-DD 00:00:00'),
    finalDate: moment(endDate).format('YYYY-MM-DD 23:59:59')
  }

  const response: AxiosResponse<ReportsExcelResponse> = await api.get(
    `/statistics/list/excel`,
    {
      params
    }
  )
  return response.data
}
