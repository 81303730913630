const INJURY_REPORT_NUMBER = '014 - 00001'

const DISABLED_ROUTES = [
  '/report-overview',
  '/reports/edit',
  '/new-user',
  '/user/'
]

const COLUMNS = 100

export { COLUMNS, DISABLED_ROUTES, INJURY_REPORT_NUMBER }
