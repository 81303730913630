import axios from 'axios'
import fileDownload from 'js-file-download'
import { isEmpty } from 'lodash'
import { createContext, useCallback, useEffect, useState } from 'react'
import { Seasons } from 'store/ducks'
import StatisticFilters, {
  FilterDataProps
} from '../containers/Statistics/Filters'
import {
  getReportsExcel,
  getStatistics,
  StatisticProps
} from '../services/statistic.service'

interface ContextCoordinatesProps {
  summer: number[]
  winter: number[]
}

interface StatisticPageContextProps {
  statistics: StatsProps
  renderFilters: () => JSX.Element
  isLoading: boolean
  filterData: FilterDataProps
  activeTab: Seasons
  coordinatesByFilteredReports: ContextCoordinatesProps
  setActiveTab: (season: Seasons) => void
  downloadFile: (activeSeason: Seasons) => any
  isDownloading: boolean
}
interface StatsProps {
  [Seasons.WINTER]: StatisticProps
  [Seasons.SUMMER]: StatisticProps
}

interface StatisticPageProviderProps {
  children: JSX.Element
}

export const StatisticPageContext = createContext(
  {} as StatisticPageContextProps
)

StatisticPageContext.displayName = 'Contact Page Context'

const StatisticPageProvider = ({ children }: StatisticPageProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<Seasons>(Seasons.WINTER)
  const [coordinatesByFilteredReports, setCoordinatesByFilteredReports] =
    useState<ContextCoordinatesProps>({
      summer: [],
      winter: []
    })
  const { data: filterData, renderFilters } = StatisticFilters()
  const [statistics, setStatistics] = useState({
    [Seasons.WINTER]: {} as StatisticProps,
    [Seasons.SUMMER]: {} as StatisticProps
  })

  const loadStatistics = useCallback(async (filters?: any) => {
    if (isEmpty(filters)) return

    setIsLoading(true)
    const winterStats = await getStatistics({
      ...filters,
      filterSeason: Seasons.WINTER
    })

    const summerStats = await getStatistics({
      ...filters,
      filterSeason: Seasons.SUMMER
    })

    setCoordinatesByFilteredReports({
      winter: winterStats?.filteredReportsIdsArray,
      summer: summerStats?.filteredReportsIdsArray
    })
    setStatistics(statistics => {
      return {
        ...statistics,
        [Seasons.WINTER]: winterStats,
        [Seasons.SUMMER]: summerStats
      }
    })

    setIsLoading(false)
  }, [])

  const downloadFile = async (activeSeason: Seasons) => {
    const { minAge, maxAge, endDate, startDate, resort, gender, nationality } =
      filterData

    const resortId = resort !== 'ALL' ? resort : ''
    const genderFilter = gender !== 'ALL' ? gender : ''
    const nationalityFilter = nationality !== 'ALL' ? nationality : ''

    setIsDownloading(true)

    try {
      const { url } = await getReportsExcel({
        resortId,
        gender: genderFilter,
        nationality: nationalityFilter,
        minAge,
        maxAge,
        endDate,
        startDate,
        filterSeason: activeSeason
      })

      const response = await axios.get(url, { responseType: 'blob' })

      fileDownload(
        response.data,
        `skaderapportering-${resort}-${activeSeason}.xlsx`
      )
    } catch (error) {
      console.error('Error downloading the file:', error)
    } finally {
      setIsDownloading(false)
    }
  }

  useEffect(() => {
    loadStatistics()
  }, [loadStatistics])

  useEffect(() => {
    const winter = statistics?.[Seasons.WINTER]
    const summer = statistics?.[Seasons.SUMMER]
    const isWinterEmpty = !winter?.totalInjuries && summer?.totalInjuries
    const isSummerEmpty = !summer?.totalInjuries && winter?.totalInjuries
    const notEmpty = winter?.totalInjuries && summer?.totalInjuries
    if (notEmpty) return
    if (isWinterEmpty) return setActiveTab(Seasons.SUMMER)
    if (isSummerEmpty) return setActiveTab(Seasons.WINTER)
  }, [statistics])

  useEffect(() => {
    const { minAge, maxAge, endDate, startDate } = filterData

    const resortId = filterData.resort !== 'ALL' ? filterData.resort : ''
    const gender = filterData.gender !== 'ALL' ? filterData.gender : ''
    const filterSeason = filterData.season
    const nationality =
      filterData.nationality !== 'ALL' ? filterData.nationality : ''

    loadStatistics({
      resortId,
      gender,
      filterSeason,
      nationality,
      minAge,
      maxAge,
      endDate,
      startDate
    })
  }, [filterData, loadStatistics])

  const value: StatisticPageContextProps = {
    statistics,
    renderFilters,
    isLoading,
    filterData,
    activeTab,
    coordinatesByFilteredReports,
    setActiveTab,
    downloadFile,
    isDownloading
  }

  return (
    <StatisticPageContext.Provider value={value}>
      {children}
    </StatisticPageContext.Provider>
  )
}

export default StatisticPageProvider
