import { IconArrowBack, IconArrowRight } from 'assets/images'
import { Button } from 'components'
import StateSection from '../Step1/StateSection'
import TimeStampsSection from '../Step1/TimeStampsSection'
import { FlexRow, FormContainer, RowItem } from '../styled'
import SurveillanceSection from './SurveillanceSection'
import TransportationSection from './TransportationSection'
import TreaterSection from './TreaterSection'

const ReportFormStep2 = ({ handleBack }: { handleBack: () => void }) => {
  return (
    <FormContainer>
      <StateSection />
      <SurveillanceSection />
      <TransportationSection />
      <TreaterSection />
      <TimeStampsSection />
      <FlexRow style={{ justifyContent: 'flex-end', padding: '80px 0' }}>
        <RowItem style={{ width: '50%' }}>
          <div style={{ marginBottom: '10px' }}>
            <Button
              style={{ marginRight: '10px' }}
              type="button"
              label="goBack"
              variant="default"
              onClick={handleBack}
              icon={<IconArrowBack fill="#fff" />}
            />
          </div>
          <Button
            type="submit"
            label="continue"
            variant="primary"
            icon={<IconArrowRight />}
          />
        </RowItem>
      </FlexRow>
    </FormContainer>
  )
}

export default ReportFormStep2
