import {
  CheckboxGroupInput,
  CheckboxInput,
  RadioGroupInput,
  RangeInput,
  TextAreaInput
} from 'components'
import {
  BLEEDINGS_SOURCE_OPTIONS,
  BOOLEAN_OPTIONS,
  BREATHING_OPTIONS,
  CIRCULATION_OPTIONS,
  CONSCIOUSNESS_OPTIONS,
  HIGH_ENERGY_OPTIONS,
  PULSE_OPTIONS,
  PULSE_SOURCE_OPTIONS
} from 'constants/options'
import { useFormikContext } from 'formik'
import { t } from 'i18n'
import { Report } from 'store/ducks'
import FormTitle from '../FormTitle'
import { FlexRow, FormSectionSubtitle, RowItem } from '../styled'

const StateSection = () => {
  const {
    values: { state },
    setFieldValue
  } = useFormikContext<Report>()

  const getPainLevelLabel = () => {
    const _painLevel = parseInt(Number(state?.painLevel).toFixed(0))

    switch (_painLevel) {
      default:
      case 0:
        return 'painLevelNoPain'
      case 1:
      case 2:
      case 3:
        return 'painLevelLow'
      case 4:
      case 5:
      case 6:
        return 'painLevelModerate'
      case 7:
      case 8:
      case 9:
      case 10:
        return 'painLevelLoud'
    }
  }

  return (
    <>
      <FormTitle title="state" />
      <FormSectionSubtitle>{t`highEnergy`}</FormSectionSubtitle>
      <FlexRow>
        <RowItem>
          <RadioGroupInput
            name="state.highEnergy"
            value={state?.highEnergy}
            options={HIGH_ENERGY_OPTIONS}
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
      <FormSectionSubtitle>{t`consciousness`}</FormSectionSubtitle>
      <FlexRow>
        <RowItem>
          <RadioGroupInput
            name="state.consciousness"
            value={state?.consciousness}
            options={CONSCIOUSNESS_OPTIONS}
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
      <FlexRow>
        <RowItem width={20}>
          <TextAreaInput
            name="state.consciousnessLevel"
            value={state?.consciousnessLevel}
            label="wasThePersonUnconsciousAtAnyPoint"
            onChange={e =>
              setFieldValue('state.consciousnessLevel', e.target.value)
            }
            placeholder={t('clickHereToWriteMore')}
          />
        </RowItem>
      </FlexRow>
      <FormSectionSubtitle>{t`bigBleedings`}</FormSectionSubtitle>
      <FlexRow>
        <RowItem>
          <RadioGroupInput
            name="state.bigBleedings"
            value={state?.bigBleedings}
            options={BOOLEAN_OPTIONS}
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
      <FlexRow>
        <RowItem>
          <CheckboxGroupInput
            name="state.bleedingsSource"
            value={state?.bleedingsSource}
            options={BLEEDINGS_SOURCE_OPTIONS}
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
      <FormSectionSubtitle>{t`breathing`}</FormSectionSubtitle>
      <FlexRow>
        <RowItem>
          <RadioGroupInput
            name="state.breathing"
            value={state?.breathing}
            options={BREATHING_OPTIONS}
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
      <FormSectionSubtitle>{t`frequency`}</FormSectionSubtitle>
      <FlexRow>
        <RowItem width={60}>
          <RangeInput
            name="state.heartbeat"
            label="frequencyPerMinute"
            value={state?.heartbeat ?? 0}
            onChange={setFieldValue}
            marks={10}
            min={0}
            max={60}
            step={1}
          />
        </RowItem>
      </FlexRow>
      <FormSectionSubtitle>{t`circulation`} 1/2</FormSectionSubtitle>
      <FlexRow>
        <RowItem>
          <RadioGroupInput
            name="state.pulse"
            value={state?.pulse}
            options={PULSE_OPTIONS}
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
      <FlexRow>
        <RowItem>
          <CheckboxGroupInput
            name="state.pulseSource"
            value={state?.pulseSource}
            options={PULSE_SOURCE_OPTIONS}
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
      <FormSectionSubtitle>{t`circulation`} 2/2</FormSectionSubtitle>
      <FlexRow>
        <RowItem>
          <CheckboxGroupInput
            name="state.circulation"
            value={state?.circulation}
            options={CIRCULATION_OPTIONS}
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
      <FormSectionSubtitle>{t`headNeckBack`}</FormSectionSubtitle>
      <FlexRow>
        <RowItem width={30} style={{ display: 'flex' }}>
          <CheckboxInput
            name="state.headInjury"
            label="headInjury"
            value={state?.headInjury}
            onChange={setFieldValue}
          />
          <CheckboxInput
            name="state.neckInjury"
            label="neckInjury"
            value={state?.neckInjury}
            onChange={setFieldValue}
          />
          <CheckboxInput
            name="state.backInjury"
            label="backInjury"
            value={state?.backInjury}
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
      <FormSectionSubtitle>{t`pain(VAS)`}</FormSectionSubtitle>
      <FlexRow>
        <RowItem width={60}>
          <RangeInput
            name="state.painLevel"
            label={getPainLevelLabel()}
            value={state?.painLevel ? Number(state?.painLevel?.toFixed(0)) : 0}
            onChange={setFieldValue}
            marks={1}
            min={0}
            max={10}
            step={1}
          />
        </RowItem>
      </FlexRow>
    </>
  )
}

export default StateSection
